h1 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 64pt;
  font-weight: bold;
  color: white;
}
h3 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18pt;
  font-weight: bold;
}
h4 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14pt;
  font-weight: bold;
}
select {
  font-size: large;
  font-weight: bold;
  text-align: center;
  height: 40px;
  width: 100px;
  margin: 20px;
  border-radius: 5%;
}
label {
  font-weight: bold;
  margin-top: 20px;
}
button {
  font-size: 32pt;
}
input[type='range'] {
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
  width: 1000px;
  margin: 10px 0;

  margin-top: 60px;
}

input[type='range']::-webkit-slider-runnable-track {
  background: #ffffff;
  height: 1rem;
  border-radius: 34px;
}
input[type='range']::-moz-range-track {
  background: #ffffff;
  height: 1rem;
  border-radius: 34px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -20px; /* Centers thumb on the track */
  background-color: #96ff72;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -20px; /* Centers thumb on the track */
  background-color: #96ff72;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
}

#volume-control::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -20px; /* Centers thumb on the track */
  background-color: #96ff72;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
}

.App {
  background-color: #f2f2f2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.header {
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  background-color: #393939;
  border-radius: 5px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.metronome-container {
  display: flex;

  flex-direction: column;
  background-color: #393939;
  border-radius: 5px;
}
.tempo-control-container {
  display: flex;
  justify-content: center;
}
.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.text-container {
  display: flex;
  justify-content: center;
}

.font-sans {
  font-family: sans-serif;
}

.mt-6 {
  margin-top: 6px;
}

.tempo-flash {
  display: flex;

  align-items: center;
  flex-direction: column;
  margin: 40px;
}
.button-container {
  display: flex;
  justify-content: center;
}
.change-tempo-button {
  height: 100px;
  width: 100px;
  background-color: #f2f2f2;
  margin: 20px;
  border-radius: 50%;
}
.start-stop-button {
  height: 80px;
  width: 100px;
  background-color: #f2f2f2;
  margin: 20px;
  border-radius: 5%;
}

.selector-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#bpm {
  text-align: center;
  margin-top: 10px;
}

#tempo {
  color: #96ff72;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 128pt;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  height: 200px;
  width: 200px;
  background-color: #393939;
  border-radius: 10%;
}
#bpm {
  color: #96ff72;
  font-size: 32pt;
}

#mute-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#mute-label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18pt;
  margin: 20px;
  margin-right: 25px;
}

#mute-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

#subdivision-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#subdivision-container > select {
  height: 80px;
  width: 300px;
  border-radius: 2%;
}

#volume-control {
  width: 300px;
}

#tap-tempo {
  height: 100px;
  width: 500px;
  background-color: #f2f2f2;
  border-radius: 5%;
  margin: 40px 40px;
}

#tap-tempo:active {
  color: white;
  background-color: white;
}

button:active {
  transform: scale(0.98);

  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

#volume-control {
  width: 400px;
  margin-left: 40px;
  margin-top: 100px;
}

.start-stop-button {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

#italian-tempo {
  color: #f2f2f2;
  font-size: 24pt;
}

#mute-label {
  color: white;
  height: 200px;
}

#meter-label {
  color: white;
  font-size: 24pt;
}

#meter-select {
  height: 60px;
  width: 100px;
  font-size: 24pt;
}
.meter-option {
  font-size: 24pt;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-self: flex-start;
}

#volume-label {
  color: white;
  font-size: 24pt;
  text-align: right;
  margin-right: 190px;
  margin-top: 40px;
}

.volume-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#temp-slider {
  margin-bottom: 80px;
}

#subdivision-select {
  font-size: 24pt;
}
